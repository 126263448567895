<template>
    <div class="magazin">

      <div v-for="(article, i) in articles" :key="i" class="magazin__item noselect">
        <router-link :to="'/articles/' + article.id">
          <img :src="'https://app.sportlerplus.com/scaled/800/' + article.main_image_url" class="magazin__item__image">
          <div v-if="article.series_subtitle" class="magazin__item__badge">{{ article.series_subtitle }}</div>
          <div v-else class="magazin__item__badge">{{ article.media_type_names }}</div>
          <div class="magazin__item__text">
            <div class="magazin__item__title">{{ article.title }}</div>
            <div class="magazin__item__copy">{{ article.intro }}</div>
            <div class="magazin__item__meta">{{ articleReadTime(article) }}</div>
          </div>
        </router-link>
      </div>
  
    </div>
  </template>
  
  <script>
  import {analytics} from '@/firebase/index.js';
  
  export default {
    name: 'ArticlesByTags',
    methods: {
        articleReadTime(article) {
            if (this.readArticleList.indexOf(article.id) != -1) {
                return "Bereits gelesen";
            }
                return article.readtime + " Min.";
            },
        init() {
            this.search_tags = this.$route.params.tags;
            this.readArticleList = this.$store.getters.getReadArticleList;
            //this.featuredArticles = this.featuredArticles.filter(f => f.feature.meta.type == 'blog.BlogPage');
            this.$store.dispatch('searchArticlesByTags', { search_tags: this.search_tags }).then((res) => {
                this.articles = res.items;
            });
            analytics.logEvent('view_articles_by_tags', {
                tags: this.search_tags,
            });
        },
    },
    components: {
    },
    computed: {
    },
    mounted() {
        this.init();
        },
    data() {
        return {
            articles: [],
            readArticleList: [],
            search_tags: null,
        }
    }
  }
  </script>
